export const Routes = {
    "Home": "/Home",
    "UsoConsultorio": "/UsoConsultorio",
    "LineaEspecializada": "/LineaEspecializada",
    "CepillosEspecializados": "/CepillosEspecializados",
    "LineaSensibilidad": "/LineaSensibilidad",
    "Multibeneficios": "/Multibeneficios",
    "LineaInfantil": "/LineaInfantil",
    "Login":"/",
    "Prescripcion": "https://www.pres.kagencia.com/login"
}